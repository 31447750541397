<template>
  <BackofficeBase :loader_prop="loader_prop">

    <h1>{{box[0].number}} :עריכת תיבה</h1>
    <form @submit = "admin_edit_box">

        <select v-model="size">
          <option disabled value=''>בחר גודל</option>
            <option value="l">גדול (40x40)</option>
            <option value="m">בינוני (40x20)</option>
            <option value="s">קטן (40x10)</option>
        </select>

        <span>  :גודל {{ size }}</span>
        <div class="input_error_txt" v-if = "size_input_error">
              size error
            </div>
        <div class="submit_cont">
              <input type="submit" value="Save" />
        </div>
    </form>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: 'BackofficeEditBoxSize',
  components: {
    BackofficeBase
  },
  data() {
    return {
      loader_prop : true,
      box : [],
      size : '',
      size_input_error :false
    }
  },
  async mounted() {
    this.id = this.$route.query.id
    let backendModel = new BackendModel()
    let box = await backendModel.backendRequest("/Api/service/backoffice/admin_get_box", {"id" : this.id})
    console.log(box)
    this.box= box.data.box
    this.loader_prop = false
  },
  methods : {
    async admin_edit_box(e){
      e.preventDefault()
      let errs = 0
      this.size_input_error = false
      if (this.size == '') {
          this.size_input_error = true
          errs++
      }
      if (errs == 0) {
          this.loader_prop = true
          let backendModel = new BackendModel()
          let params = {
            size : this.size,
            id : this.id
          }
          let unit_id = this.box[0].unit_id 
        await backendModel.backendRequest("/Api/service/backoffice/admin_edit_box", params)
        await this.$router.push({ path: '/backoffice/edit_boxes', query :  {"id" : unit_id} })

      }
    }
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>
